.page-questions{
    // background-image: url('../../public/images/bg-question.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    @apply relative;
    @apply text-white;
    &:before{
        content: '';
        @apply w-full h-[55%] top-[45%] left-0 block absolute;
        background-image: url('../../public/images/deco.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 50px;
    }
}
.question{
    @apply w-full h-dvh relative;
}
.question-area{
    @apply relative h-[50%] w-full flex flex-col items-center justify-end pb-lg text-center;
}
.answer-area{
    @apply relative h-[50%] w-full flex flex-col items-center justify-center;
}
.answer-block{
    @apply bg-primary text-white inline-flex items-center font-bold ;
    @apply border border-white rounded-[10px] relative;
    overflow-x: hidden;
    transform-origin: 50% 50%;
    &:before{
        @apply absolute top-0 left-0 w-full h-full block;
        transform: translateX(-100%);
        @apply bg-gradient-to-r from-primary from-0% via-primary-light via-30% to-primary to-60%;
        content: '';
    }
    &.active{
        @apply scale-[1.05];
        transition: all 0.3s ease;
        &:before{
            transform: translateX(100%);
            transition: all 0.5s ease;
        }
    }
    &.muted{
        @apply bg-primary-mute;
        transition: all 0.3s ease;
    }
}


.progress-container{
    @apply absolute top-[32px] left-0 w-full h-auto;
    overflow-x: hidden;
}
.progress-dots{
    --transition-speed: 0.3s;
    --dot-width: 16px;
    --dot-space: 48px;
    --num-size: 64px;
    @apply flex items-center relative;
    width: calc( 10*var(--dot-width) + 9*var(--dot-space) );
    left: calc( 50% - 0.5*var(--dot-width) );
    transition: all var(--transition-speed) ease;

    @for $i from 1 through 10 {
        &.dot-#{$i}{
            transform: translateX( calc( (0 - #{$i}) * (var(--dot-width) + var(--dot-space)) ) );
        }
    }

    .progress-line{
        @apply h-[1px] bg-white w-[var(--dot-space)];
        flex: 0 0 auto;
    }
    .progress-dot{
        @apply relative w-[var(--dot-width)] h-[var(--dot-width)] rounded-full border border-white;
        flex: 0 0 auto;
        &.completed{
            @apply bg-white;
        }
        &.active{
            .progress-num{
                @apply scale-100;
                transition-delay: var(--transition-speed);
            }        
        }
    }
    .progress-num{
        @apply w-[var(--num-size)] h-[var(--num-size)] rounded-full border border-white;
        @apply flex items-center justify-center;
        @apply bg-gradient-to-r from-gold-light to-gold text-gold-text font-bold text-[32px];
        @apply absolute top-1/2 left-1/2 scale-0;
        transition: all var(--transition-speed) ease;
        margin-top: calc( -0.5 * var(--num-size) );
        margin-left: calc( -0.5 * var(--num-size) );
    }

    .item{
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        &:last-child{
            .progress-line{
                display: none;
            }
        }
    }
}